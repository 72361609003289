import React from 'react';
import PropTypes from 'prop-types';
import styles from 'risk_management/styles/Confirmation';

class ConfirmationContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ul className={styles.confirmationList}>
        <li className={styles.confirmationHeader}>
          {this.props.headerName}
        </li>
        <ul>
          {this.renderDisplayRows()}
        </ul>
      </ul>
    );
  }

  renderDisplayRows = () => {
    if (this.props.records.length) {
      const TimePeriodDataTableRow = this.props.rowComponent;
      return (
        this.props.records.map((record) =>
          <TimePeriodDataTableRow
            key={record.id}
            record={record}
          />
        ));
    } else {
      return (this.renderEmpty());
    }
  };

  renderEmpty = () => {
    return (
      <>
        <span className={styles.confirmationItem}>
        No records selected.
        </span>
      </>
    );
  };
}

ConfirmationContainer.propTypes = {
  records: PropTypes.array.isRequired,
  rowComponent: PropTypes.func.isRequired,
  headerName: PropTypes.array,
};

export default ConfirmationContainer;
