import React from 'react';
import TimePeriodPicker from 'reporting/section/TimePeriodPicker';
import RiskCategoryDefinitionPicker from 'reporting/section/RiskCategoryDefinitionPicker';
import RiskAttributeRiskEntityDefinitionPicker from 'reporting/section/RiskAttributeRiskEntityDefinitionPicker';
import RiskAttributeIndicatorRiskEntityDefinitionPicker from 'reporting/section/RiskAttributeIndicatorRiskEntityDefinitionPicker';
import LineOfBusinessPicker from 'reporting/section/LineOfBusinessPicker';
import DepartmentPicker from 'reporting/section/DepartmentPicker';
import AppetiteLevelPicker from 'reporting/section/AppetiteLevelPicker';
import FormGroup from 'reporting/section/FormGroup';
import FormCheckbox from 'reporting/section/FormCheckbox';
import TagFilterModePicker from 'reporting/section/TagFilterModePicker';
import MultiSelectWithLoader from 'reporting/section/MultiSelectWithLoader';
import kriOwnerOptionsLoader from 'reporting/section/kriOwnerOptionsLoader';
import kriOwnerGroupOptionsLoader from 'reporting/section/kriOwnerGroupOptionsLoader';
import riskTagOptionsLoader from 'reporting/section/riskTagOptionsLoader';
import QuestionInfoTooltip from 'global/components/QuestionInfoTooltip';

function KRIReportAdvanced(props) {
  const {
    timePeriod,
    risk_category_definition_id,
    risk_attribute_risk_entity_definition_ids,
    line_of_business_risk_entity_definition_id,
    department_risk_entity_definition_id,
    appetite_level,
    tag_filter_mode,
    onChange
  } = props;

  const tag_filter_mode_styles = {
    container: base => ({
      ...base,
      flexBasis: '7em'
    })
  };
  const risk_tags_styles = {
    container: base => ({
      ...base,
      flexGrow: 10
    })
  };

  return (
    <div>
      <FormGroup label='Time Period'>
        <TimePeriodPicker
          value={timePeriod}
          onChange={(value) => onChange({timePeriod: value})}
          showQuarters
          showMonths={window.riskEntitySettings.monthly_kri}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.risk_category_label}>
        <RiskCategoryDefinitionPicker
          value={risk_category_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label='Risk Attribute'>
        <RiskAttributeRiskEntityDefinitionPicker
          name='risk_attribute_risk_entity_definition_ids'
          value={risk_attribute_risk_entity_definition_ids}
          onChange={onChange}
          risk_category_definition_id={risk_category_definition_id}
        />
      </FormGroup>

      <FormGroup label='Indicator'>
        <RiskAttributeIndicatorRiskEntityDefinitionPicker
          name='risk_attribute_indicator_risk_entity_definition_ids'
          value={props.risk_attribute_indicator_risk_entity_definition_ids}
          onChange={onChange}
          risk_category_definition_id={risk_category_definition_id}
          risk_attribute_risk_entity_definition_ids={risk_attribute_risk_entity_definition_ids}
        />
      </FormGroup>

      <FormGroup label='Line of Business'>
        <LineOfBusinessPicker
          risk_category_definition_id={risk_category_definition_id}
          value={line_of_business_risk_entity_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.department_label}>
        <DepartmentPicker
          risk_category_definition_id={risk_category_definition_id}
          line_of_business_risk_entity_definition_id={line_of_business_risk_entity_definition_id}
          value={department_risk_entity_definition_id}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label='KRI Owner'>
        <MultiSelectWithLoader
          name='kri_owners'
          value={props.kri_owners}
          loader={kriOwnerOptionsLoader}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label='KRI Owner Group'>
        <MultiSelectWithLoader
          name='kri_owner_groups'
          value={props.kri_owner_groups}
          loader={kriOwnerGroupOptionsLoader}
          onChange={onChange}
        />
      </FormGroup>

      <FormGroup label={window.riskEntitySettings.indicator_risk_appetite_rating_label}>
        <AppetiteLevelPicker
          value={appetite_level}
          onChange={onChange}
        />
      </FormGroup>

      <div className='form-group'>
        <div>
          <label>
            Tags
            <QuestionInfoTooltip
              content={'All of: Include results tagged with all of the selected tags. Any of: Include results tagged with any of the selected tags.'}
              html={false}
              placement='bottom'
              trigger='hover'
            />
          </label>
        </div>
        <div style={{display: 'flex'}}>
          <TagFilterModePicker
            value={tag_filter_mode}
            onChange={onChange}
            styles={tag_filter_mode_styles}
          />
          <MultiSelectWithLoader
            name='risk_tags'
            value={props.risk_tags}
            loader={riskTagOptionsLoader}
            onChange={props.onChange}
            styles={risk_tags_styles}
          />
        </div>
      </div>

      <FormGroup label='Include'>
        <FormCheckbox
          label='Summary'
          name='include_summary'
          value={props.include_summary}
          defaultValue={true}
          onChange={onChange}
        />
        <FormCheckbox
          label='Trend Chart'
          name='include_trend_chart'
          value={props.include_trend_chart}
          defaultValue={true}
          onChange={onChange}
        />
        <FormCheckbox
          label='KRI Appetite Table'
          name='include_kri_appetite'
          value={props.include_kri_appetite}
          defaultValue={true}
          onChange={onChange}
        />
        <FormCheckbox
          label='KRI Detailted View'
          name='include_kri_detailed'
          value={props.include_kri_detailed}
          defaultValue={true}
          onChange={onChange}
        />
      </FormGroup>

      <div className={props.include_kri_detailed == 'true' ? 'form-group' : 'hidden form-group'}>
        <div>
          <label>Show Additional KRI Details</label>
        </div>
        <div>
          <FormCheckbox
            label='Description'
            name='show_kri_description'
            value={props.show_kri_description}
            defaultValue={true}
            onChange={onChange}
          />
          <FormCheckbox
            label='Justification'
            name='show_kri_justification'
            value={props.show_kri_justification}
            defaultValue={true}
            onChange={onChange}
          />
          <br />
          <FormCheckbox
            label='Target Value'
            name='show_kri_target_value'
            value={props.show_kri_target_value}
            defaultValue={true}
            onChange={onChange}
          />
          <FormCheckbox
            label='Policy Limit'
            name='show_kri_policy_limit'
            value={props.show_kri_policy_limit}
            defaultValue={true}
            onChange={onChange}
          />
          <FormCheckbox
            label='Forecasted Value'
            name='show_kri_forecasted_value'
            value={props.show_kri_forecasted_value}
            defaultValue={true}
            onChange={onChange}
          />
          <FormCheckbox
            label='Previous Value'
            name='show_kri_previous_value'
            value={props.show_kri_previous_value}
            defaultValue={true}
            onChange={onChange}
          />
          <br />
          <FormCheckbox
            label='Tags'
            name='show_kri_tags'
            value={props.show_kri_tags}
            defaultValue={true}
            onChange={onChange}
          />
          <FormCheckbox
            label='KRI Histoical Trends'
            name='show_kri_historical'
            value={props.show_kri_historical}
            defaultValue={true}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

KRIReportAdvanced.title = 'KRI Report Section Configuration';

export default KRIReportAdvanced;
