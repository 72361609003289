import React from 'react';
import Select from 'react-select';
import ax from 'global/axios';
import _ from 'lodash';

const loader = _.memoize( (risk_category_definition_id) => {
  return ax({
    url: '/risk_category_definitions/risk_attribute_risk_entity_definitions',
    method: 'post', // Note: post is inconsistent with ReST
    data: {
      risk_category_definition_ids: [risk_category_definition_id],
      enforce_category: true
    }
  });
});

class RiskAttributeRiskEntityDefinitionPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      error: null,
      value: []
    };
  }

  load() {
    const {risk_category_definition_id} = this.props;
    this.setState({loading: true});
    loader(risk_category_definition_id)
      .then( (res) => {
        const options = res
          .data
          .risk_attribute_risk_entity_definitions
          .map( rared => ({value: rared.id, label: rared.name}) );
        const selectedValue = _.filter(options, (option) => _.includes(this.props.value, option.value));
        this.setState({
          options: options,
          loading: false,
          error: null,
          value: selectedValue
        });
      }, (error) => {
        this.setState({
          options: [],
          loading: false,
          error: error
        });
      });
  }

  noOptionsMessage = () => {
    const {error} = this.state;
    if (error) {
      return 'Error loading Risk Attributes';
    } else {
      return 'No Risk Attribute Available';
    }
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.risk_category_definition_id != prevProps.risk_category_definition_id) {
      this.load();
    }
  }

  handleChange = (options) => {
    const value = _.filter(options, (option) => _.includes(this.state.options, option));
    this.setState({value});

    const raredIds = _.map(options, _.property('value'));
    this.props.onChange({[this.props.name]: raredIds});
  };

  render() {
    return (
      <Select
        name={this.props.name}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        placeholder='Risk Attribute'
        options={this.state.options}
        noOptionsMessage={this.noOptionsMessage}
        value={this.state.value}
        isLoading={this.state.loading}
        onChange={this.handleChange}
      />
    );
  }
}

export default RiskAttributeRiskEntityDefinitionPicker;
