import React from 'react';
import Form from 'react-bootstrap/Form';

class FormCheckbox extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOnChange = (e) => {
    let checked = e.currentTarget.checked;
    this.setState({isChecked: checked});
    this.props.onChange({[this.props.name]: checked ? 'true' : 'false'});
  };

  render() {
    const check_box_section = {
      marginLeft: '20px',
      display: 'inline-block'
    };
    const check_box_label = {
      fontSize: '12px',
      marginRight: '5px'
    };
    const check_box_input = {
      verticalAlign: 'text-bottom'
    };

    const {label, name} = this.props;
    return (
      <div style={check_box_section}>
        <Form.Check type='checkbox' id={name}>
          <Form.Check.Label style={check_box_label}>{label}</Form.Check.Label>
          <Form.Check.Input
            name={name}
            type='checkbox'
            value={'true'}
            checked={this.props.value == 'true'}
            onChange={this.handleOnChange}
            style={check_box_input}
          />
        </Form.Check>
      </div>
    );
  }
}

export default FormCheckbox;
