import React from 'react';
import Select from 'react-select';
import ax from 'global/axios';
import _ from 'lodash';

const loader = _.memoize( (currentProps) => {
  let {risk_category_definition_id, risk_attribute_risk_entity_definition_ids} = currentProps;
  return ax({
    url: '/risk_category_definitions/risk_attribute_indicator_risk_entity_definitions',
    method: 'post', // Note: post is inconsistent with ReST
    data: {
      risk_category_definition_ids: [risk_category_definition_id],
      risk_attribute_risk_entity_definition_ids: risk_attribute_risk_entity_definition_ids,
      enforce_category: true
    }
  });
});

class RiskAttributeIndicatorRiskEntityDefinitionPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      error: null,
      value: []
    };
  }

  load() {
    this.setState({loading: true});
    loader(this.props)
      .then( (res) => {
        const options = res
          .data
          .risk_attribute_indicator_risk_entity_definitions
          .map( raired => ({value: raired.id, label: raired.name}) );
        const selectedValue = _.filter(options, (option) => _.includes(this.props.value, option.value));
        this.setState({
          options: options,
          loading: false,
          error: null,
          value: selectedValue
        });
      }, (error) => {
        this.setState({
          options: [],
          loading: false,
          error: error
        });
      });
  }

  noOptionsMessage = () => {
    const {error} = this.state;
    if (error) {
      return 'Error loading Risk Attribute Indicators';
    } else {
      return 'No Risk Attribute Indicator Available';
    }
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.risk_category_definition_id != prevProps.risk_category_definition_id || this.props.risk_attribute_risk_entity_definition_ids != prevProps.risk_attribute_risk_entity_definition_ids) {
      this.load();
    }
  }

  handleChange = (options) => {
    const value = _.filter(options, (option) => _.includes(this.state.options, option));
    this.setState({value});

    const rairedIds = _.map(options, _.property('value'));
    this.props.onChange({[this.props.name]: rairedIds});
  };

  render() {
    return (
      <Select
        name={this.props.name}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        placeholder='Risk Attribute Indicator'
        options={this.state.options}
        noOptionsMessage={this.noOptionsMessage}
        value={this.state.value}
        isLoading={this.state.loading}
        onChange={this.handleChange}
      />
    );
  }
}

export default RiskAttributeIndicatorRiskEntityDefinitionPicker;
